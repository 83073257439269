(function () {
    'use strict';

    /**
    * @memberof app
    * @ngdoc controller
    * @name ProyectoController
    * @description
    * This is main controller
    **/

    angular
        .module('app')
        .controller('ProyectoController', ProyectoController);

    ProyectoController.$inject = ["$scope", "ProyectoService", "$timeout"];

    function ProyectoController($scope, ProyectoService, $timeout) {

        var vm = this;
        vm.filtros = {};
        vm.categoria_proyecto = "";
        $scope.categoria_proyecto = vm.filtros.categoria_proyecto;
        vm.numPaginas = 0;
        vm.page = 0;

        vm.showProyectos = function (init) {
            ProyectoService.getProyectos(vm.filtros, vm).then(function (data) {
                vm.proyectos = data.proyectos;
                vm.categoriasproyectos = data.categoriasproyectos;
                vm.numPaginas = data.numPaginas;
                setTimeout(vm.animation)
            });
        }

        vm.next = function () {
            vm.page++;
            vm.showProyectos();
        };

        vm.prev = function () {
            vm.page--;
            vm.showProyectos();
        };

        vm.resetFilters = function () {
            $timeout(function () {
                vm.filtros.categoria_proyecto = {};
                $scope.categoria_proyecto = vm.filtros.categoria_proyecto;
                vm.filtros.categoria_proyecto["todos"] = true;
            });

        }

        $scope.$watch("categoria_proyecto", function (newValue, oldValue) {
            //console.log(newValue);
            var b = false;
            angular.forEach(newValue, function (item) {
                if (item) {
                    b = true;
                }
            });
            if (!b)
                vm.resetFilters();

            return true;

        }, true);

        vm.animation = function () {

            vm.figure = angular.element(document.querySelectorAll('.qw__figure'))
            vm.image = angular.element(document.querySelectorAll('.qw__img'))
            vm.content = angular.element(document.querySelectorAll('.qw__con'))

            console.log("proyectos")

            let timeline = gsap.timeline();

            timeline.from(vm.figure, {
                duration: 1.5,
                clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
                ease: "circ.out",
            })
            
            timeline.from(vm.image, {
                duration: 1.5,
                clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
                ease: "circ.out",
            }, .8)

            timeline.to(vm.content, {
                autoAlpha: 1,
                ease: "circ.out",
            }, "<")

            ScrollTrigger.create({
                trigger: vm.elementos,
                start: "top center",
                // markers: true,
                toggleActions: "play none none none",
                animation: timeline
            });
        }

    }

})();