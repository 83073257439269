(function(){
     'use strict';

     angular
        .module('app')
        .factory('BlogService', BlogService);

    BlogService.$inject = ['$http'];

    function BlogService($http){

        var service= {
           getBlogs:getBlogs,
        };

        return service;


        function getBlogs(filtros,vm)
        {
            var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                }
            }
           
            return $http.get('/ajax/getblogs', { params : { filtros : JSON.stringify(filtros),page:vm.page}  }, config)
            .then(function(data){
                return data.data;
            },function(data){
                return data;
            });


        }

         
    }
})();