(function () {
    'use strict';

    /**
    * @memberof app
    * @ngdoc controller
    * @name BlogController
    * @description
    * This is main controller
    **/

    angular
        .module('app')
        .controller('BlogController', BlogController);

    BlogController.$inject = ["$scope", "BlogService", "$timeout", "$element", "$document"];

    function BlogController($scope, BlogService, $timeout, $element, $document) {

        var vm = this;
        vm.filtros = {};
        vm.categoria_blog = "";
        $scope.categoria_blog = vm.filtros.categoria_blog;
        vm.numPaginas = 0;
        vm.page = 0;

        vm.showBlogs = function (init) {
            BlogService.getBlogs(vm.filtros, vm).then(function (data) {
                vm.blogs = data.blogs;
                vm.categoriasblog = data.categoriasblog;
                vm.numPaginas = data.numPaginas;
                // vm.elementos = angular.element(document.querySelectorAll('.container'));
                setTimeout(vm.animation)
            });
        }

        vm.next = function () {
            vm.page++;
            vm.showBlogs();
        };

        vm.prev = function () {
            vm.page--;
            vm.showBlogs();
        };

        vm.resetFilters = function () {
            $timeout(function () {
                vm.filtros.categoria_blog = {};
                $scope.categoria_blog = vm.filtros.categoria_blog;
                vm.filtros.categoria_blog["todos"] = true;
            });

        }

        $scope.$watch("categoria_blog", function (newValue, oldValue) {
            //console.log(newValue);
            var b = false;
            angular.forEach(newValue, function (item) {
                if (item) {
                    b = true;
                }
            });
            if (!b)
                vm.resetFilters();

            return true;

        }, true);

        vm.animation = function () {

            vm.figure = angular.element(document.querySelectorAll('.c-blog__figure'))
            vm.image = angular.element(document.querySelectorAll('.c-blog__img'))
            vm.content = angular.element(document.querySelectorAll('.c-blog__content'))

            let timeline = gsap.timeline();

            timeline.from(vm.figure, {
                duration: 1.5,
                clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
                ease: "circ.out",
            })
            
            timeline.from(vm.image, {
                duration: 1.5,
                clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
                ease: "circ.out",
            }, .8)

            timeline.from(vm.content, {
                autoAlpha: 0,
                ease: "circ.out",
            }, "<")

            ScrollTrigger.create({
                trigger: vm.elementos,
                start: "top center",
                // markers: true,
                toggleActions: "play none none none",
                animation: timeline
            });
        }
    }

})();