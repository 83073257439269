(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name UserController
* @description
* This is main controller
**/ 

    angular
    .module('app')
    .controller('UserController', UserController);

    UserController.$inject = ["UserService","$timeout","$location",'$window'];

    function UserController(UserService,$timeout,$location,$window)
    {
        var vm = this;
        vm.code = 0;
        vm.username = "";
        vm.password = "";
        vm.msgerror = "";
        vm.msg = "";
        vm.errmsg ="";
        vm.finalpass ="";
        vm.msgemail ="";
        vm.msglogin = false;
        vm.step = 0;

        vm.login = function(){
            UserService.Login(vm).then(function(data){ 
            vm.msgerror = data.msg;
            vm.msglogin = data.msglogin;
            vm.msgemail = data.msgemail;
            vm.username = data.username;
            vm.password = data.password;
                // console.log("login",vm.msglogin);
                // console.log("user",vm.username);
                // console.log("pass",vm.password);
                // console.log("email",vm.msgemail);
                // console.log("error",vm.msgerror);
            
                if (data.slugPage ) {
                    window.location = data.slugPage;
                    window.open(data.slugPage,'_system');
                }
            });
        } ;
        

        vm.recovery = function(){
            UserService.Recuperarpassword(vm).then(function(data){
                vm.msgerror = data.msgerror;
                vm.msgemail = data.msgemail;
               console.log(vm.msgerror);
               console.log(vm.msgemail);
                if (vm.msgerror == undefined && vm.msgemail) {
                   vm.step = 2;
                }

            });
        };

        vm.changepassword = function(form){
            UserService.Changepassword(vm).then(function(data){
            vm.finalpass = data.finalpass;
            vm.password = data.password;
            vm.password_rep = data.password_rep;
            vm.msgerror = data.msgerror;
            console.log("1",vm.finalpass);
            if ( vm.finalpass != undefined) {
                form.$setPristine();
                form.$setUntouched();
            console.log("2",vm.finalpass);

            }
         
            });
        };

        vm.reset = function(form) {
          vm.username ="";
          vm.password ="";
          vm.reseteado = true;
          form.$setPristine();

        }

        vm.ModalRecuPass = function(){
            console.log("hola");
            var modalAbon = new bootstrap.Modal(document.getElementById('ModalRecuPass'), {
                      keyboard: false
                    })
                modalAbon.show()
        }
        
    }

})();