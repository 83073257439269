(function() {
    'use strict';
    angular.module('app',['ui.bootstrap','ngSanitize','ngAnimate','uiGmapgoogle-maps'])
   .config(function(uiGmapGoogleMapApiProvider) {

        
          uiGmapGoogleMapApiProvider.configure({
            key: 'AIzaSyByKWeX0og4lYkbUynl4SFbOogKbamWj2o',
            // v: '3.20',
            libraries: 'geometry,places',
            language:'es-es',
          });
        })
    .config(function($httpProvider) {
        // $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        $httpProvider.defaults.headers.post['X-CSRF-Token'] = jQuery('meta[name="csrf-token"]').attr("content");
        $httpProvider.defaults.timeout = 30000;
    })
    .config(["$interpolateProvider",function($interpolateProvider){
        $interpolateProvider.startSymbol("[[");
        $interpolateProvider.endSymbol("]]");
    }]);
})();
 