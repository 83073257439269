(function () {
    'use strict';

    /**
    * @memberof app
    * @ngdoc controller
    * @name NoticiaController
    * @description
    * This is main controller
    **/

    angular
        .module('app')
        .controller('NoticiaController', NoticiaController);

    NoticiaController.$inject = ["$scope", "NoticiaService", "$timeout"];

    function NoticiaController($scope, NoticiaService, $timeout) {

        var vm = this;
        vm.filtros = {};
        vm.categoria_noticia = "";
        $scope.categoria_noticia = vm.filtros.categoria_noticia;
        vm.numPaginas = 0;
        vm.page = 0;

        vm.showNoticias = function (init) {
            NoticiaService.getNoticias(vm.filtros, vm).then(function (data) {
                vm.noticias = data.noticias;
                vm.categoriasnoticia = data.categoriasnoticia;
                vm.numPaginas = data.numPaginas;
                setTimeout(vm.animation)
            });
        }

        vm.next = function () {
            vm.page++;
            vm.showNoticias();
        };

        vm.prev = function () {
            vm.page--;
            vm.showNoticias();
        };

        vm.resetFilters = function () {
            $timeout(function () {
                vm.filtros.categoria_noticia = {};
                $scope.categoria_noticia = vm.filtros.categoria_noticia;
                vm.filtros.categoria_noticia["todos"] = true;
            });

        }

        $scope.$watch("categoria_noticia", function (newValue, oldValue) {
            //console.log(newValue);
            var b = false;
            angular.forEach(newValue, function (item) {
                if (item) {
                    b = true;
                }
            });
            if (!b)
                vm.resetFilters();

            return true;

        }, true);

        vm.animation = function () {

            vm.li = angular.element(document.querySelectorAll('.new-list li'));
            vm.images = angular.element(document.querySelectorAll('.new-image'));

            // newListItems.forEach(item => {

            //     item.addEventListener('mouseenter', () => {
            //         const newId = item.getAttribute('data-new');

            //         newImages.forEach(img => {
            //             img.classList.remove('active');
            //             if (img.id === newId) {
            //                 img.classList.add('active');
            //             }
            //         });
            //     });
            // });
        }

    }

})();