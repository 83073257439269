(function() {
    'use strict';
    /**
     * @memberof app
     *@ngdoc service
     *@name UserService
     *@description
     *Servicio de autores
     */

    angular
        .module('app')
        .factory('UserService', UserService);

    UserService.$inject = ["$http","$window","$location"];

    function UserService($http,$window,$location){  

        var sesion = {
            Login:Login,
            Recuperarpassword:Recuperarpassword,
            Changepassword:Changepassword,
        };

        function Login(vm){
            var config = {
                headers : {}
            }
            return $http.post(
                '/ajax/login',{
                  LoginForm:{username:vm.username, password:vm.password, name:vm.name}
                }
                ,config)
            .then(function(data){
                return data.data;
            },function(data){
                return data;
            });
            

        };


        function Recuperarpassword(vm){
            var config = {
                headers : {}
            } 
            return $http.post('/ajax/recoverypassword', {
                  RecoveryForm:{
                      username:vm.username,
                      msgemail:vm.msgemail
                  }
             } , config)

            .then(function(data){
                return data.data;
            },function(data){
                return data;
            });
        };

        function Changepassword(vm){
            var config = {
                headers : {}
            } 
            return $http.post('/ajax/recoverypassword', {
                  RecoveryForm:{
                      password:vm.password,
                      password_rep:vm.password_rep,
                      username: vm.username,
                      token:vm.token,
                  },
                  action:"change"
             } , config)

            .then(function(data){
                return data.data;
            },function(data){
                return data;
            });
        };


      return sesion;
    }
})();

